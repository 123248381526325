<template>
    <div>
      <el-card>
        <div class="page">
          <el-form :model="AddFormData" label-width="100px">
            <el-form-item label="虚拟实验ID">
              <!-- <el-input v-model="AddFormData.exp_id"></el-input> -->
              <el-select  
                    filterable
                    remote
                    clearable
                    v-model="AddFormData.exp_id" 
                    placeholder="请搜索选择虚拟实验"
                    :remote-method="getExpList" 
                    class="expInputSearch" >
                  <el-option
                    v-for="item in expList"
                    :label="item.name+'-'+item.id"
                    :value="item.id"
                  ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="链接">
              <el-input v-model="AddFormData.url"></el-input>
            </el-form-item>
            <el-form-item label="ilabx链接">
              <el-input v-model="AddFormData.ilabx_url"></el-input>
            </el-form-item>
            <el-form-item label="学校名称">
              <el-select
                v-model="AddFormData.school_name"
                filterable
                placeholder="请选择学校"
                @change="getSchoolID"
              >
                <el-option
                  v-for="item in schoolList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学校ID">
              <el-input v-model="AddFormData.school_id" disabled></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input v-model="AddFormData.fuzeren"></el-input>
            </el-form-item>
            <el-form-item label="虚拟实验名称">
              <el-input v-model="AddFormData.exp_name"></el-input>
            </el-form-item>
            <el-form-item label="项目级别">
              <el-select
                v-model="AddFormData.level"
                filterable
                placeholder="请选择项目级别"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类">
              <el-select
                v-model="AddFormData.lab_id"
                filterable
                placeholder="请选择分类"
              >
                <el-option
                  v-for="item in labList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="简介视频">
              <el-input v-model="AddFormData.video"></el-input>
            </el-form-item>
  
            <el-form-item label="简介视频图片">
              <!-- <el-input v-model="AddFormData.video_img"></el-input> -->
              <el-upload
                  class="avatar-uploader el-upload-padding"
                  :action="uploadFilePath"
                  :show-file-list="false"
                  :data = {path:header}
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="AddFormData.video_img" :src="AddFormData.video_img" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              
            </el-form-item>
  
            <el-form-item label="引导视频">
              <el-input v-model="AddFormData.guideVideo"></el-input>
            </el-form-item>
  
            <el-form-item label="引导视频图片">
              <el-input v-model="AddFormData.guideVideoImg"></el-input>
            </el-form-item>
  
            <el-form-item label="logo">
              <el-input v-model="AddFormData.logo"></el-input>
            </el-form-item>
  
            <el-form-item label="标题">
              <el-input v-model="AddFormData.title"></el-input>
            </el-form-item>
  
            <el-form-item label="安卓二维码">
              <el-input v-model="AddFormData.android_img"></el-input>
            </el-form-item>
  
            <el-form-item label="pc_download">
              <el-input v-model="AddFormData.pc_download"></el-input>
            </el-form-item>
  
            <el-form-item label="团队信息">
              <el-input
                type="textarea"
                autosize
                v-model="AddFormData.team_json"
              ></el-input>
            </el-form-item>
  
            <el-form-item label="项目ID">
              <el-select
                v-model="AddFormData.project_id"
                filterable
                remote
                placeholder="请搜索选择项目"
                :remote-method="serachProject"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
  
            <!-- <el-form-item label="secret">
              <el-input v-model="AddFormData.secret"></el-input>
            </el-form-item> -->
  
            <el-form-item label="secret">
              <el-input placeholder="请输入ilab密钥" v-model="AddFormData.aesKey"></el-input>
            </el-form-item>
  
            <el-form-item label="ilabID">
              <el-input placeholder="请输入ilabID" v-model="AddFormData.issueId"></el-input>
            </el-form-item>
  
            <el-form-item label="ilabx标题">
              <el-input v-model="AddFormData.projecttitle"></el-input>
            </el-form-item>
  
            <el-form-item label="webgl_url">
              <el-input v-model="AddFormData.webgl_url"></el-input>
            </el-form-item>
  
            <el-form-item label="base64_url">
              <el-input v-model="AddFormData.base64_url"></el-input>
            </el-form-item>
  
            <el-form-item label="android_download">
              <el-input v-model="AddFormData.android_download"></el-input>
            </el-form-item>
  
            <el-form-item label="count">
              <el-input v-model="AddFormData.count"></el-input>
            </el-form-item>
  
            <el-form-item label="学科分类">
              <el-input v-model="AddFormData.nlab_id"></el-input>
            </el-form-item>
  
            <el-form-item label="描述">
              <el-input v-model="AddFormData.des"></el-input>
            </el-form-item>
  
            <el-form-item label="第三方密钥">
              <el-input v-model="AddFormData.password"></el-input>
            </el-form-item>
  
            <el-form-item label="电话">
              <el-input v-model="AddFormData.phone"></el-input>
            </el-form-item>
  
            <el-form-item label="版权">
              <el-input v-model="AddFormData.copyright"></el-input>
            </el-form-item>
  
            <el-form-item label="地址">
              <el-input v-model="AddFormData.address"></el-input>
            </el-form-item>
  
            <el-form-item label="版本">
              <el-input v-model="AddFormData.version"></el-input>
            </el-form-item>
  
            <el-form-item label="步骤">
              <el-input
                type="textarea"
                autosize
                v-model="AddFormData.steps"
              ></el-input>
            </el-form-item>
  
            <div v-if="isVersion2">
              <el-form-item label="描述">
                <vue-ueditor-wrap
                  v-model="AddFormData.desc_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="头部图片">
                <!-- <vue-ueditor-wrap
                  v-model="AddFormData.header_image_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap> -->
                <el-input class="logoPic" v-model="AddFormData.header_image_v2"></el-input>
                <div class="uploadTips">填写图片链接或点击下方上传图片两种方式均可</div>
                <el-upload
                  class="avatar-uploader el-upload-padding"
                  :action="uploadFilePath"
                  :show-file-list="false"
                  :data = {path:header}
                  :on-success="handleLogoSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="AddFormData.header_image_v2" :src="AddFormData.header_image_v2" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              </el-form-item>
              <el-form-item label="实验目的">
                <vue-ueditor-wrap
                  v-model="AddFormData.purpose_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="服务团队">
                <vue-ueditor-wrap
                  v-model="AddFormData.team_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="实验原理">
                <vue-ueditor-wrap
                  v-model="AddFormData.principle_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="实验方法与步骤">
                <vue-ueditor-wrap
                  v-model="AddFormData.steps_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="实验考核要求">
                <vue-ueditor-wrap
                  v-model="AddFormData.requirements_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="实验项目特色">
                <vue-ueditor-wrap
                  v-model="AddFormData.characteristics_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="面向学生要求">
                <vue-ueditor-wrap
                  v-model="AddFormData.requirement_v2"
                  :config="myConfig"
                >
                </vue-ueditor-wrap>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
      <div class="save">
        <el-button class="themecancelButton" @click="Back">返回</el-button>
        <el-button class="themeButton" @click="saveReportWeb">保存</el-button>
      </div>
    </div>
  </template>
  <style lang="scss" scoped>
  @import "@/assets/css/manager/editorWeb/editWebDetail.scss";
  </style>
  <script>
  import EditReportWeb from "@/assets/js/manager/editorWeb/editWebDetail.js";
  export default {
    ...EditReportWeb,
  };
  </script>