import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import API from '@/api/manager/editorWeb/editorWeb';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "editReportWeb",
    components: {
        VueUeditorWrap
    },
    data() {
        return{
            // ueditor 编辑器配置项
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "70%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            //   编辑网站内容数据
            AddFormData:{
            },
            // 是否是第二版
            isVersion2:false,
            // 学校列表
            schoolList:[],
            // 项目列表
            projectList:[],
            // 分类列表
            labList:[],
            // 上传文件地址
            uploadFilePath: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminVirtualSimulation/uploadImagePic",
            // 上传头部图标识
            header:'header',
            // 上传视频引导图片标识
            videopic:'videopic',
            // 上传安卓二维码标识
            android_img:'android_img',
            // 虚拟实验列表
            expList:[],
            // 项目等级列表
            levelList:[
                {
                    id:'0',
                    name:'默认'
                },
                {
                    id:'1',
                    name:'ilabx'
                },
                {
                    id:'2',
                    name:'省一流'
                },
                {
                    id:'3',
                    name:'国一流'
                }
            ]
        }
    },
    created(){
        let self = this;
        self.getReportWebDetail();
    },
    methods:{
        /**
         * @name: 获取虚拟实验
         * @author: lxy
         * @date: 20210908
         */
        getExpList(e) {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: e,
                    // lab_id:self.lab_id,
                    // school_id:self.school_id,
                    // page:self.currentPage,
                    // pagesize:self.pageSize
                }
                // 获取虚拟实验请求
                API.getExpList(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.expList = result.logList
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 安卓下载二维码上传
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
         handleTwoCodeSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.android_img = res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        /**
         * @name: 头部图片上传成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        handleAvatarSuccessVideo(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.header_image_v2 = res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        /**
         * @name: 头部图片上传成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        handleLogoSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.header_image_v2 = res.data;
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        /**
         * @name: 简介视频图片上传成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        handleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.AddFormData.video_img = res.data;
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            
            else 
            {
                // Message.error(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },
        /**
         * @name: 上传图片前校验
         * @author: camellia
         * @date: 2021-03-01
         * @param:	file	json	文件流
         */
        beforeAvatarUpload(file) 
        {
            const isJPG =
            file.type === "image/jpeg" ||
            "image/png" ||
            "image/jpg" ||
            "image/gif" ||
            "image/bmp";
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) {
            // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
            this.$Tips({
                // 消息提示内容
                message:"上传头像图片只能是 JPG/png/gif/bmp 格式",
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                button:[
                    {
                        type:1,
                        text:'知道了'
                    }
                ]
            })
            }
            if (!isLt2M) {
            // Message.error("上传图片大小不能超过 5MB!");
            this.$Tips({
                // 消息提示内容
                message:"上传图片大小不能超过 5MB",
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                button:[
                    {
                        type:1,
                        text:'知道了'
                    }
                ]
            })
            }
            return isJPG && isLt2M;
        },
        /**
         * @name: 获取申报网站内容详情
         * @author: LANXINYU
         * @date: 20211119
         */
        getReportWebDetail()
        {
            let self = this;
            let id = self.$route.query.id;
            let detail_id = self.$route.query.detail_id;
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            let data = {
                id:id,
                detail_id:detail_id
            }
            API.getReportWebDetail(data)
                .then((res)=>{
                    if(res.code > 0)
                    {
                        self.AddFormData=res.data[0];
                        self.schoolList = res.schoolList;
                        self.labList = res.labList;
                        self.expList.push({
                            id:res.data[0].exp_id,
                            name:res.data[0].exp_name
                        })
                        if(detail_id!=='null')
                        {
                            self.isVersion2 = true;
                        }
                        nprogressClose();
                        self.$Loading.hide();
                    }
                })
        },
        /**
         * @name: 保存申报网站内容详情
         * @author: LANXINYU
         * @date: 20211119
         */
        saveReportWeb()
        {
            let self = this;
            let data = {
                id:self.$route.params.id,
                ...self.AddFormData
            }
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            API.updateReportWeb(data)
                .then((res)=>{
                    if(res.code>0)
                    {
                        // Message.success('保存成功');
                        this.$Tips({
                            // 消息提示内容
                            message:res.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        },
        /**
         * @name: 点击返回按钮，返回上一页
         * @desc: 描述
         * @author: LANIXNYU
         * @date: 20211119
         */
        Back()
        {
            let self =this;
            self.$router.back()
        },
        /**
         * @name: 学校选择框失去焦点，获取学校ID
         * @author: LANXINYU
         * @date: 20211123
         */
        getSchoolID(school_name)
        {
            let self = this;
            for (let item of self.schoolList) {
                if(item.name==school_name)
                {
                    self.AddFormData.school_id = item.id
                    return;
                }
            }
        },
        /**
         * @name: 搜索项目
         * @desc: 描述
         * @author: LANXINYU
         * @date: 20211123
         */
        serachProject(value)
        {
            let self = this;
            let data = {
                name:value
            }
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.axios
                .post("/index.php/adminVirtualSimulation/getProjectBySearch",data)
                .then((res)=>{
                    self.projectList = res.data.projectList;
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        }
    },
}