import { request } from "@/request/http";

/**
 * @name: 学校管理接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
    // 获取虚拟实验列表
    getExpList: (data) => {
        return request("POST", `/index.php/adminExp/getExpList`, data);
    },
    createCourseAndProjectAndGroup: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/createCourseAndProjectAndGroup`, data);
    },
    loginManager: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/loginManager`, data);
    },
    getTeacherList: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getTeacherList`, data);
    },
    getReportWebList: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getReportWebList`, data);
    },
    deleteReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/deleteReportWeb`, data);
    },
    addReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/addReportWeb`, data);
    },
    getReportWebDetail: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/getReportWebDetail`, data);
    },
    updateReportWeb: (data) => {
        return request("POST", `/index.php/adminVirtualSimulation/updateReportWeb`, data);
    },
};